<template>
  <v-dialog
    persistent
    v-model="articleDialogAdd"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.article.dialogEdit.textTitle }}</p>
      <v-container
        class="pa-0 mt-5"
        fluid
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <div>
              <v-select
                v-model="articleCategoryType"
                :label="texts.article.dialogEdit.textArticleCategoryType"
                :placeholder="texts.article.dialogEdit.textArticleCategoryType"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :items="aCatalogsTypes"
                item-text="sName"
                item-value="sCategoryTypeId"
                clearable
                @change="selectCategory"
              ></v-select>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div>
              <v-select
                v-model="articleCategory"
                :label="texts.article.dialogEdit.textArticleCategory"
                :placeholder="texts.article.dialogEdit.textArticleCategory"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :items="aCatalogs"
                item-text="sName"
                item-value="sCategoryId"
                clearable
                @change="selectSubcategory"
              ></v-select>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div>
              <v-select
                v-model="articleSubcategory"
                :label="texts.article.dialogEdit.textArticleSubcategory"
                :placeholder="texts.article.dialogEdit.textArticleSubcategory"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :items="aSubcatalogs"
                item-text="sSubcategoryName"
                item-value="sSubcategoryId"
                clearable
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-text-field
                v-model="articleNameEs"
                type="text"
                :label="texts.article.dialogEdit.textArticleNameEs"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.article.dialogEdit.textArticleNameEs"
                persistent-placeholder
                clearable
                maxLength="40"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-text-field
                v-model="articleNameEn"
                type="text"
                :label="texts.article.dialogEdit.textArticleNameEn"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.article.dialogEdit.textArticleNameEn"
                persistent-placeholder
                clearable
                maxLength="40"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <div>
              <v-text-field
                v-model="articleLotNumber"
                type="text"
                :label="texts.article.dialogEdit.textArticleLotNumber"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.article.dialogEdit.textArticleLotNumber"
                persistent-placeholder
                clearable
                maxLength="40"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div>
              <v-text-field
                v-model="articleBoxQuantity"
                type="text"
                :label="texts.article.dialogEdit.textArticleBoxQuantity"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.article.dialogEdit.textArticleBoxQuantity"
                persistent-placeholder
                clearable
                maxLength="10"
                @keypress="soloNumbers"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <div>
              <v-text-field
                v-model="articlePiecesPerBox"
                type="text"
                :label="textPiecesPerBox()"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="textPiecesPerBox()"
                persistent-placeholder
                clearable
                maxLength="10"
                @keypress="soloNumbers"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <div>
              <v-text-field
                v-model="articleWeightPerBox"
                type="text"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :label="textWeightPerBox()"
                :placeholder="textWeightPerBox()"
                persistent-placeholder
                suffix="LB"
                clearable
                maxLength="5"
                @keypress="soloNumbers"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <div>
              <div style="position: relative;">
                <p class="label-input-curreny mon-regular">{{ textArticlePrice() }}</p>
                <currency-input
                  class="input-currency mon-regular"
                  maxlength="12"
                  v-model="articlePrice"
                  :distraction-free="false"
                  currency="MXN"
                  locale="es-MX"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <div>
              <v-text-field
                v-model="articleMinAmount"
                type="text"
                :label="textArticleMinAmount()"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="textArticleMinAmount()"
                persistent-placeholder
                clearable
                maxLength="10"
                @keypress="soloNumbers"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div>
              <v-text-field
                v-model="articleBoxesPerPallet"
                type="text"
                :label="textBoxesPerPallet()"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="textBoxesPerPallet()"
                persistent-placeholder
                clearable
                maxLength="10"
                @keypress="soloNumbers"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div>
              <v-select
                v-model="articleMeasureUnit"
                :label="texts.article.dialogEdit.textArticleMeasureUnit"
                :placeholder="texts.article.dialogEdit.textArticleMeasureUnit"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :items="aMeasureUnits"
                item-text="sName"
                item-value="sMeasurementUnitId"
                clearable
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <div>
              <v-select
                v-model="articleWarehouse"
                :label="texts.article.dialogEdit.textArticleWarehouse"
                :placeholder="texts.article.dialogEdit.textArticleWarehouse"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :items="aWarehouses"
                item-text="oWarehouse.sName"
                item-value="sVendorWarehouseId"
                clearable
              ></v-select>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div class="display-flex align-items-center">
              <v-menu
                ref="bMenuFinal"
                v-model="bMenuFinal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    readonly
                    v-model="articleDisponibility"
                    clearable
                    hide-details
                    append-icon="mdi-calendar"
                    @click:clear="articleDisponibility = null"
                    :placeholder="texts.article.dialogEdit.textArticleDisponibility"
                    :label="texts.article.dialogEdit.textArticleDisponibility"
                    persistent-hint
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="#C41F79"
                  v-model="articleDisponibility"
                  no-title
                  @input="bMenuFinal = false"
                ></v-date-picker>
              </v-menu>
              <div
                class="ml-1"
                style="width: 170px"
              >
                <v-text-field
                  v-model="articleDisponibilityHour"
                  placeholder="00:00"
                  type="time"
                  min="1:00"
                  max="24:00"
                  outlined
                  color="#000000"
                  dense
                  class="global-inputs mon-regular"
                  persistent-placeholder
                  suffix="24 Hrs"
                  maxLength="5"
                  @keypress="soloNumbers"
                ></v-text-field>
              </div>
            </div>
          </v-col>
          <!-- <v-col
            cols="12"
            md="4"
          >
            <div>
              <v-text-field
                v-model="articlePreparationTime"
                type="text"
                :label="texts.article.dialogEdit.textArticlePreparationTime"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.article.dialogEdit.textArticlePreparationTime"
                persistent-placeholder
                clearable
                prepend-inner-icon="mdi-clock"
                suffix="Hrs"
                maxLength="2"
                @keypress="soloNumbers"
              ></v-text-field>
            </div>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-text-field
                v-model="articleTemperatureWarehouse"
                type="text"
                :label="texts.article.dialogEdit.textArticleTemperatureWarehouse"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.article.dialogEdit.textArticleTemperatureWarehouse"
                persistent-placeholder
                clearable
                suffix="F°"
                maxLength="3"
                @keypress="soloNumbers"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-text-field
                v-model="articleTemperatureTraslado"
                type="text"
                :label="texts.article.dialogEdit.textArticleTemperatureTraslado"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.article.dialogEdit.textArticleTemperatureTraslado"
                persistent-placeholder
                clearable
                suffix="F°"
                maxLength="3"
                @keypress="soloNumbers"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-textarea
                v-model="articleDescriptionEn"
                class="global-inputs mon-regular"
                :label="texts.article.dialogEdit.textArticleDescriptionEn"
                :placeholder="texts.article.dialogEdit.textArticleDescriptionEn"
                outlined
                color="#000000"
                dense
                rows="3"
                maxLength="250"
              ></v-textarea>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-textarea
                v-model="articleDescriptionEs"
                class="global-inputs mon-regular"
                :label="texts.article.dialogEdit.textArticleDescriptionEs"
                :placeholder="texts.article.dialogEdit.textArticleDescriptionEs"
                outlined
                color="#000000"
                dense
                rows="3"
                maxLength="250"
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="mt-4">
              <p class="text-title-photography mon-bold">{{ texts.article.dialogEdit.textPhographyTitle }}</p>
              <p class="text-subtitle-photography mon-regular">{{ texts.article.dialogEdit.textPhographySubtitle }}</p>
              <v-card-text>
                <div class="status-input-zone">
                  <div
                    v-for="(item, index) in dataImgs"
                    :key="index"
                    class="content-image-articles"
                  >
                    <v-btn
                      @click="removeItem(item, index)"
                      icon
                      class="button-delete-item"
                    >
                      <v-icon
                        size="25px"
                        color="#D8686A"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                    <img
                      :src="maskedFile(item)"
                      alt=""
                    >
                  </div>
                  <div
                    class="mx-2 my-1"
                    v-if="(dataImgs.length < 10)"
                  >
                    <v-btn
                      @click="$refs.inputUpload.click()"
                      class="button-add-document mon-bold "
                      elevation="0"
                    >
                      <div>
                        <v-icon
                          color="#B2B2B2"
                          size="60px"
                        >
                          mdi-image-plus
                        </v-icon>

                      </div>
                      <input
                        ref="inputUpload"
                        style="display: none;"
                        type="file"
                        size="60"
                        accept=".jpg, .jpeg, .png"
                        multiple="true"
                        @change="uploadFilesWarehouse"
                      >
                    </v-btn>
                  </div>
                </div>
                <div class="justify-end d-flex">
                  {{dataImgs.length}} / 10
                </div>
              </v-card-text>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="border-bottom" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="display-flex align-items-center justify-content-flex-end">
              <v-btn
                elevation="0"
                @click="closeDialog"
                class="button-secondary mon-regular mr-1"
              >
                {{ texts.article.dialogEdit.textButtonCancel }}
              </v-btn>
              <v-btn
                :loading="bLoading"
                :disabled="!validateForm"
                elevation="0"
                @click="saveArticle"
                class="button-primary mon-bold ml-1"
              >
                {{ texts.article.dialogEdit.textButtonSave }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ArticleDialogAddLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    //VARIABLES
    return {
      articleDisponibilityHour: "",
      aMeasureUnits: [],
      aCatalogsTypes: [],
      aCatalogs: [],
      aSubcatalogs: [],
      aWarehouses: [],
      dataImgs: [],
      articleNameEs: "",
      articleNameEn: "",
      articleCategoryType: "",
      articleCategory: "",
      articleSubcategory: "",
      articleLotNumber: "",
      articleDescriptionEs: "",
      articleDescriptionEn: "",
      articleBoxQuantity: "",
      articlePrice: 0,
      articleWarehouse: "",
      // articlePreparationTime: "",
      articleTemperatureWarehouse: "",
      articleTemperatureTraslado: "",
      articleMinAmount: "",
      articleMeasureUnit: "42eb37cc-19c4-4316-923f-a892c0cb5681",
      articleBoxesPerPallet: "",
      articlePiecesPerBox: "",
      articleWeightPerBox: "",
      screenWidth: 0,
      articleDisponibility: null,
      bMenuFinal: false,
      bLoading: false,
    };
  },
  beforeMount() {
    this.getCatalogsTypes();
    this.getWarehouses();
    this.getMeasureUnits();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    removeItem: function (item, index) {
      this.dataImgs.splice(index, 1);
    },
    maskedFile: function (file) {
      return URL.createObjectURL(file);
    },
    capitalize: function (str) {
      let lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice("1");
    },
    textWeightPerBox: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            "Peso por " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        } else {
          text =
            "Weight per " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        }
      text = this.capitalize(text);
      return text;
    },
    textPiecesPerBox: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            "Piezas por " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        } else {
          text =
            "Aprox pieces per " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        }
      text = this.capitalize(text);
      return text;
    },
    textArticlePrice: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            "Precio por " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        } else {
          text =
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName + " price";
        }
      text = this.capitalize(text);
      return text;
    },
    textArticleMinAmount: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            "Mínimo de " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName +
            " disponibles para la venta";
        } else {
          text =
            "Minimum amount of " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName +
            " available for sale";
        }
      text = this.capitalize(text);
      return text;
    },
    textBoxesPerPallet: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName + " por pallet";
        } else {
          text =
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName + " per pallet";
        }
      return text;
    },
    uploadFilesWarehouse: function (e) {
      let totalImg = 0;
      const files = e.target.files;
      totalImg = this.dataImgs.length + files.length;
      if (totalImg < 11) {
        for (let i = 0; i < files.length; i++) {
          const element = files[i];
          this.dataImgs.push(element);
        }
        e.target.value = "";
      } else {
        if (this.selectLanguage == "sp") {
          this.mixError("No puede capturar más de 10 imágenes.");
        } else {
          this.mixError("Cannot capture more than 10 images.");
        }
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    setDates(date) {
      let hour = this.articleDisponibilityHour.split(":")[0];
      let minutes = this.articleDisponibilityHour.split(":")[1];
      date = new Date(date);
      date.setHours(hour, minutes);
      return date;
    },
    saveArticle: function () {
      this.bLoading = true;

      const payload = {
        sSubcategoryId: this.articleSubcategory,
        sVendorWarehouseId: this.articleWarehouse,
        dMinAmountAllowed: this.articleMinAmount,
        dUnitPrice: this.articlePrice,
        sMeasurementUnitId: this.articleMeasureUnit,
        tProductAvailableTime: this.setDates(this.articleDisponibility),
        // iPreparationTime: this.articlePreparationTime,
        sLotId: this.articleLotNumber,
        dTransportationTemperature: this.articleTemperatureTraslado,
        dStorageTemperature: this.articleTemperatureWarehouse,
        dQuantity: this.articleBoxQuantity,
        dBoxesPerPallet: this.articleBoxesPerPallet,
        dPiecesPerBox: this.articlePiecesPerBox,
        dWeightPerBox: this.articleWeightPerBox,
        aTranslations: [
          {
            sLang: "en",
            sName: this.articleNameEn,
            sDescription: this.articleDescriptionEn,
          },
          {
            sLang: "sp",
            sName: this.articleNameEs,
            sDescription: this.articleDescriptionEs,
          },
        ],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/products`, payload, config)
        .then((response) => {
          this.uploadImages(response.data.results.sProductId);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    uploadImages: function (id) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      var form = new FormData();
      this.dataImgs.forEach((element) => {
        if (element) {
          form.append("image", element);
        }
      });

      if (!form.entries().next().done)
        DB.patch(
          `${URI}/api/v1/${this.selectLanguage}/products/${id}/storage`,
          form,
          config
        )
          .then((response) => {
            this.bLoading = false;

            this.mixSuccess(response.data.message);
            this.closeDialog();
            this.$store.commit("refresher", true);
          })
          .catch((error) => {
            this.bLoading = false;

            this.mixError(error.response.data.message, error.response.status);
          });
    },
    getMeasureUnits: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(`${URI}/api/v1/${this.selectLanguage}/measurement_units`, config)
        .then((response) => {
          this.aMeasureUnits = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getWarehouses: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
        config
      )
        .then((response) => {
          this.aWarehouses = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getCatalogsTypes: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/types`, {
        headers: {},
        params: {},
      })
        .then((response) => {
          this.aCatalogsTypes = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectCategory: function (id) {
      this.getCatalogs(id);
    },
    getCatalogs: function (id) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/categories?sCategoryTypeId=${id}`,
        {
          headers: {},
          params: {},
        }
      )
        .then((response) => {
          this.aCatalogs = response.data.results;
          this.getSubcatalogs();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectSubcategory: function (id) {
      this.getSubcatalogs(id);
    },
    getSubcatalogs: function (id) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/subcategories?sCategoryId=${id}`,
        {
          headers: {},
          params: {},
        }
      )
        .then((response) => {
          this.aSubcatalogs = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 80;
      } else {
        this.screenWidth = 100;
      }
    },
    closeDialog: function () {
      this.$store.commit("setArticleDialogAdd", false);
      this.articleNameEs = "";
      this.articleNameEn = "";
      this.articleCategoryType = "";
      this.articleCategory = "";
      this.articleSubcategory = "";
      this.articleLotNumber = "";
      this.articleDescriptionEs = "";
      this.articleDescriptionEn = "";
      this.articleBoxQuantity = "";
      this.articlePrice = 0;
      this.articleWarehouse = "";
      // this.articlePreparationTime = "";
      this.articleTemperatureWarehouse = "";
      this.articleTemperatureTraslado = "";
      this.articleMinAmount = "";
      this.articleMeasureUnit = "42eb37cc-19c4-4316-923f-a892c0cb5681";
      this.articleBoxesPerPallet = "";
      this.articlePiecesPerBox = "";
      this.articleWeightPerBox = "";
      this.dataImgs = [];
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    articleDialogAdd: function () {
      return this.$store.state.articleDialogAdd;
    },
    validateForm: function () {
      return (
        this.articleNameEs !== "" &&
        this.articleNameEn !== "" &&
        this.articleCategoryType !== "" &&
        this.articleCategory !== "" &&
        this.articleSubcategory !== "" &&
        this.articleLotNumber !== "" &&
        this.articleDescriptionEs !== "" &&
        this.articleDescriptionEn !== "" &&
        this.articleBoxQuantity !== "" &&
        this.articlePrice !== 0 &&
        this.articlePrice !== "" &&
        this.articleWarehouse !== "" &&
        this.articleDisponibility !== "" &&
        // this.articlePreparationTime !== "" &&
        this.articleTemperatureWarehouse !== "" &&
        this.articleTemperatureTraslado !== "" &&
        this.articleMinAmount !== "" &&
        this.articleMeasureUnit !== "" &&
        this.articleBoxesPerPallet !== "" &&
        this.articlePiecesPerBox !== "" &&
        this.articleWeightPerBox !== "" &&
        this.articleNameEs !== null &&
        this.articleNameEn !== null &&
        this.articleCategoryType !== null &&
        this.articleCategory !== null &&
        this.articleSubcategory !== null &&
        this.articleLotNumber !== null &&
        this.articleDescriptionEs !== null &&
        this.articleDescriptionEn !== null &&
        this.articleBoxQuantity !== null &&
        this.articlePrice !== null &&
        this.articleWarehouse !== null &&
        this.articleDisponibility !== null &&
        // this.articlePreparationTime !== null &&
        this.articleTemperatureWarehouse !== null &&
        this.articleTemperatureTraslado !== null &&
        this.articleMinAmount !== null &&
        this.articleMeasureUnit !== null &&
        this.articleBoxesPerPallet !== null &&
        this.articlePiecesPerBox !== null &&
        this.articleWeightPerBox !== null &&
        this.dataImgs.length > 0
      );
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getCatalogsTypes();
        this.getWarehouses();
      }
    },
    articleDialogAdd: function () {
      if (this.articleDialogAdd) {
        this.getCatalogsTypes();
        this.getWarehouses();
        this.getMeasureUnits();
      }
    },
  },
};
</script>

<style scoped>
.status-input-zone {
  overflow: auto;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #70707070;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
}

.content-image-articles {
  width: 120px;
  height: 120px;
  margin: 5px;
  position: relative;
  border: 1px solid #70707080;
  border-radius: 20px;
}

.button-delete-item {
  position: absolute;
  right: 1px;
  top: 1px;
  min-width: 40px !important;
  height: 40px !important;
  background-color: #ffffff99 !important;
  padding: 0px !important;
  border-radius: 20px;
}

.button-add-document {
  width: 120px !important;
  height: 120px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #70707080;
  border-radius: 20px;
  opacity: 1;
}

.border-bottom {
  border-bottom: 1px solid #cecece;
}

.content-image-articles {
  width: 180px;
  height: 160px;
  border-radius: 20px;
}

.content-image-articles img {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

@supports (object-fit: cover) {
  .content-image-articles img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}

.text-title-photography {
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #304458;
  opacity: 1;
  margin-bottom: 0px;
}

.text-subtitle-photography {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.text-title {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
  position: relative;
}
</style>